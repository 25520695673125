import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";
import "swiper/css";
import "swiper/css/grid";
import { SwiperSlide } from "swiper/react";

import { BaseLink } from "scmp-app/components/common/base-link";
import { ContentItemPostMagazineDiscovery } from "scmp-app/components/content/content-item-render/variants/post-magazine-discovery";
import {
  ActionBar,
  CoverImage,
  StyledCoverEntityLink,
  StyledHeadlineEntityLink,
  Summary,
} from "scmp-app/components/content/content-item-render/variants/post-magazine-discovery/styles";
import { Swiper } from "scmp-app/components/swiper";

type ContainerProps = {
  $isInit: boolean;
};
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  align-self: stretch;

  padding-block: 24px;
  padding-inline: 20px;

  border: 1px solid #000000;
  border-radius: 16px;

  visibility: ${props => (props.$isInit ? "visible" : "hidden")};

  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    align-items: center;
  }
`;

export const Title = styled.div`
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const StyledSwiper = styled(Swiper)`
  &.swiper {
    padding-block-end: 32px;

    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-end: -16px;
      padding-block-end: 0;
    }

    .swiper-pagination {
      inset-block-end: 0;

      .swiper-pagination-bullet {
        margin: 0;

        background-color: #bbbbbb;

        opacity: 0.5;
        &:not(:last-child) {
          margin-inline-end: 8px;
        }
      }

      .swiper-pagination-bullet-active {
        background-color: #000000;

        opacity: 1;
      }
    }
  }
`;

export const StyledContentItemPostMagazineDiscovery = styled(ContentItemPostMagazineDiscovery)`
  display: flex;
  flex: 1;
  flex-direction: column;

  border-block-end: 1px solid rgba(0, 0, 0, 0.2);
  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    border-block-end: 0;

    ${StyledCoverEntityLink} {
      margin-block-end: 12px;
    }
  }
`;
export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  align-items: center;

  block-size: calc((100% - 16px) / 2) !important;

  ${StyledContentItemPostMagazineDiscovery} {
    &:not(:last-child) {
      margin-block-end: 16px;

      ${props => props.theme.breakpoints.up("homeDesktop")} {
        margin-block-end: 24px;
      }
    }
  }
`;

export const MoreLink = styled(BaseLink)`
  align-self: center;

  padding-block: 7px;
  padding-inline: 8px;

  color: #111111;
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  border: 1px solid #111111;
  border-radius: 2px;

  ${props => props.theme.breakpoints.up("tablet")} {
    align-self: flex-start;
  }

  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    margin-block-start: 0;
  }
`;

export const SwiperContainer = styled.div`
  max-inline-size: 100%;

  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    display: none;
  }
`;

export const TabletItems = styled.div`
  display: none;

  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    padding-block-end: 24px;
    border-block-end: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

export const FirstItem = styled.div`
  ${StyledContentItemPostMagazineDiscovery} {
    padding-block-end: 0;
    ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
      ${StyledCoverEntityLink} {
        aspect-ratio: 1/1;

        ${CoverImage} {
          aspect-ratio: 1/1;
        }
      }

      ${ActionBar} {
        margin-block: 12px 0;
      }
    }

    ${Summary} {
      margin-block-start: 12px;
    }
  }
`;

export const RestItems = styled.div`
  ${StyledContentItemPostMagazineDiscovery} {
    display: grid;
    grid:
      "cover-image-link . headline-link" min-content
      "cover-image-link . action-bar" min-content
      / 96px 12px 1fr;
    align-items: flex-start;

    padding-block-end: 0;

    &:not(:last-child) {
      margin-block-end: 16px;
      padding-block-end: 16px;
      border-block-end: 1px solid rgba(0, 0, 0, 0.2);
    }

    ${StyledCoverEntityLink} {
      grid-area: cover-image-link;

      inline-size: 96px;
      block-size: 96px;
      margin-block: 0 auto;
    }

    ${ActionBar} {
      grid-area: action-bar;

      margin-block: 12px 0;
    }

    ${StyledHeadlineEntityLink} {
      grid-area: headline-link;
    }
  }
`;

export const Publication = styled.div`
  align-self: center;

  color: #666666;
  font-weight: 400;
  font-size: 11px;
  font-style: italic;
  line-height: 12.89px;

  ${props => props.theme.breakpoints.up("tablet")} {
    align-self: flex-start;
  }
`;
